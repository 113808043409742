<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import Layout from '@/components/Layout/Layout'

export default {
  name: 'app',
  components: {
    Layout
  }
}
</script>