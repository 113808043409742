import Vue from "vue";
import Router from "vue-router"

import Layout from '@/components/Layout/Layout';


Vue.use(Router);

const url = location.pathname.split('/');
let purl = url[1];

if (location.search.includes('?preview.')) {
    let previewPath = location.search;
    purl = previewPath.replace('?preview', 'preview');
    window.location.href = `/${purl}`
}

if (typeof purl === "undefined") {
    purl = ''
}

import DocRaptor from '@/components/Layout/DocRaptor';

export default new Router({
    mode: 'history',
    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    linkExactActiveClass: 'is-active',
    base: `/${purl}`,

    routes: [
        { path: '/upload' },
        { path: '/vr' },
        { 
            path: '/docraptor',
            name: "docraptor",
            component: DocRaptor
        }
    ]
});