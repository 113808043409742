<template>
  <section
    id="EstimateOverview"
    :style="{ backgroundImage: `url(${bg})` }"
    :class="{ docraptor: $route.name == 'docraptor' }"
  >
    <div class="estimate">
      <h1>
        Estimate
        <div class="line"></div>
      </h1>
      <div class="estimate-inputs" v-if="overview">
        <div class="divider top"></div>
        <router-link
          :to="rentalFeeLink ? rentalFeeLink : ''"
          class="estimate-inputs-item"
        >
          <img src="@/assets/icons/Custom/floorplan-icon.svg" class="rental" />
          <div class="middle">
            <article v-if="isNaN(overview.rentalFee)">
              {{ overview.rentalFee }}
            </article>
            <article v-else>{{ overview.rentalFee | currency }}</article>
            <h2>Rental Fee</h2>
          </div>
          <img src="@/assets/icons/Custom/top-left-arrow.svg" class="arrow" />
        </router-link>
        <router-link
          :to="foodBeverageLink ? foodBeverageLink : ''"
          class="estimate-inputs-item"
        >
          <img src="@/assets/icons/Custom/banquet-icon.svg" class="food" />
          <div class="middle">
            <article v-if="isNaN(overview.foodBeverageFee)">
              {{ overview.foodBeverageFee }}
            </article>
            <article v-else>{{ overview.foodBeverageFee | currency }}</article>
            <h2>Food & Beverage</h2>
          </div>
          <img src="@/assets/icons/Custom/top-left-arrow.svg" class="arrow" />
        </router-link>
        <router-link
          :to="eventExpensesLink ? eventExpensesLink : ''"
          class="estimate-inputs-item"
        >
          <img
            src="@/assets/icons/Custom/icon-event-expenses.svg"
            class="expenses"
          />
          <div class="middle">
            <article v-if="isNaN(overview.eventExpenseFee)">
              {{ overview.eventExpenseFee }}
            </article>
            <article v-else>{{ overview.eventExpenseFee | currency }}</article>
            <h2>Event Expenses</h2>
          </div>
          <img src="@/assets/icons/Custom/top-left-arrow.svg" class="arrow" />
        </router-link>
        <div class="divider bottom"></div>
        <div class="estimate-inputs-total">
          <img src="@/assets/icons/Custom/icon-total-cost.svg" class="total" />
          <div class="middle">
            <article class="total-cost" v-if="isNaN(overview.totalCost)">
              {{ overview.totalCost }}
            </article>
            <article class="total-cost" v-else>
              {{ overview.totalCost | currency }}
            </article>
            <h2>Total Cost</h2>
          </div>
        </div>

        <div
          class="estimate-inputs-total"
          v-if="overview.discountTitle && overview.discountCost"
        >
          <img src="@/assets/icons/Custom/icon-total-cost.svg" class="total" />
          <div class="middle">
            <article class="total-cost" v-if="isNaN(overview.discountCost)">
              {{ overview.discountCost }}
            </article>
            <article class="total-cost" v-else>
              {{ overview.discountCost | currency }}
            </article>
            <h2>{{ overview.discountTitle }}</h2>
          </div>
        </div>

        <div
          class="estimate-inputs-total"
          v-if="overview.totalDiscountTitle && overview.totalDiscountCost"
        >
          <img src="@/assets/icons/Custom/icon-total-cost.svg" class="total" />
          <div class="middle">
            <article
              class="total-cost"
              v-if="isNaN(overview.totalDiscountCost)"
            >
              {{ overview.totalDiscountCost }}
            </article>
            <article class="total-cost" v-else>
              {{ overview.totalDiscountCost | currency }}
            </article>
            <h2>{{ overview.totalDiscountTitle }}</h2>
          </div>
        </div>
        <div
          v-if="
            overview.perPersonCost != '' &&
            overview.perPersonCost != '0' &&
            !isNaN(overview.perPersonCost)
          "
          class="estimate-inputs-total"
        >
          <img src="@/assets/icons/Custom/person-icon.svg" class="person" />
          <div class="middle">
            <article v-if="isNaN(overview.perPersonCost)">
              {{ overview.perPersonCost }}
            </article>
            <article v-else>{{ overview.perPersonCost | currency }}</article>
            <h2>Per Person Cost</h2>
          </div>
        </div>
        <div class="estimate-inputs-total">
          <div class="middle">
            <p class="note" v-if="overview.totalDiscountCostNote">
              {{ overview.totalDiscountCostNote }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EstimateeOverview",
  data() {
    return {
      overview: null,
      bg: null,
      rentalFeeLink: null,
      foodBeverageLink: null,
      eventExpensesLink: null,
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;

    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "105") {
          this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
        }
        if (page.id === "108") {
          this.overview = JSON.parse(page.copy);
        }

        if (page.id === "109") {
          this.rentalFeeLink = "/customTab/estimate/rentalFee";
        }
        if (page.id === "110") {
          this.foodBeverageLink = "/customTab/estimate/foodBeverage";
        }
        if (page.id === "111") {
          this.eventExpensesLink = "/customTab/estimate/eventExpenses";
        }
      });
    }

    if (this.$route.name === "docraptor") {
      setTimeout(() => {
        let pages = this.$store.state.epitch.ePitch.pages;
        pages.forEach((page) => {
          if (page.id === "105") {
            this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
          }
          if (page.id === "108") {
            // window.console.log(page.copy)
            this.overview = JSON.parse(page.copy);
          }
        });
      }, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
#EstimateOverview {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/images/Custom/why-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.84) 48%,
      rgba(0, 0, 0, 0) 89%
    );
    @media (max-width: $lg) {
      // background-image: none;
    }
  }
  @media (max-width: $lg) {
    min-height: auto;
    padding-top: 86px;
  }
  .estimate {
    height: 100%;
    width: 100%;
    padding: 190px 0 0 345px;
    position: relative;
    z-index: 1;
    @media (max-width: $lg) {
      height: auto;
      padding: 40px 0 47px 44px;
    }
    @media (max-width: $md) {
      padding-left: 50px;
    }
    @media (max-width: $sm) {
      padding-left: 20px;
    }
    h1 {
      font-family: "ArtegraSans-Bold";
      font-size: 35px;
      color: #ffffff;
      letter-spacing: 2.19px;
      line-height: 30px;
      text-transform: uppercase;
      width: fit-content;
      .line {
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
        margin: 20px 0;
        @media (min-width: $lg) {
          display: none;
        }
      }
    }
    &-inputs {
      width: 95%;
      max-width: 600px;
      .divider {
        width: 100%;
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
      }
      .top {
        margin: 21px 0 34px 0;
        @media (max-width: $lg) {
          display: none;
        }
      }
      .bottom {
        margin-bottom: 27px;
      }
      &-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100px;
        width: 100%;
        background-image: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.43) 100%
        );
        margin-bottom: 10px;
        text-decoration: none;
        .rental {
          margin: 0 16px 0 25px;
        }
        .food {
          margin: 0 12px 0 18px;
        }
        .expenses {
          margin: 0 16px 0 23px;
        }
        .arrow {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        article {
          font-family: "ArtegraSans-Light";
          font-size: 31px;
          color: #ffffff;
          letter-spacing: 1.94px;
          text-transform: uppercase;
          line-height: 28px;
          margin-bottom: 7px;
        }
        h2 {
          font-family: "ArtegraSans-Bold";
          font-size: 12px;
          color: #ffffff;
          letter-spacing: 0.8px;
          text-transform: uppercase;
        }
        .note {
          font-family: "ArtegraSans-Regular";
          font-size: 15px;
          color: #ffffff;
          line-height: 23px;
          text-align: center;
          opacity: 0.8;
          margin-top: -25px;
          margin-left: 25px;
        }
      }
      &-total {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 90px;
        width: 100%;
        .middle {
          .total-cost {
            color: #ffd200;
          }
        }
        .total {
          margin: 0 16px 0 23px;
        }
        .person {
          margin: 0 19px 0 26px;
        }
      }
    }
  }
}
@media print, screen {
  #EstimateOverview.docraptor {
    background-image: url("~@/assets/images/Custom/why-pdf.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 900px;
    width: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    //height: 900px;
    width: 100%;

    .estimate {
      padding: 120px 100px 30px 100px;
      .estimate-inputs {
        max-width: none;
        width: 100%;
      }
    }

    .estimate-inputs .middle article {
      font-size: 20px;
      line-height: 20px;
    }

    .estimate-inputs-item,
    .estimate-inputs-total {
      height: 60px;

      .arrow {
        display: none;
      }
      .note {
        font-family: "ArtegraSans-Regular";
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0.8px;
        line-height: 23px;
        text-align: center;
      }
    }
  }
}
</style>