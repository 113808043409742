<template>
  <div>
    <Component
      v-if="filePath !== null"
      :is="require(`@/assets/images/${filePath}.svg`).default"
      class="svgImport"
      v-bind="$attrs"
      @v-on="$listeners"
    />

  </div>
</template>

<script>
export default {
  name: "SvgImport",

  // Transparent wrapper component
  // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
  inheritAttrs: false,

  props: {
    filePath: {
      type: String,
      required: false,
      default: "",
    }
  }
};
</script>

<style>
.svgImport {
  /* Add some default CSS declaration blocks */
}
</style>