<template>
  <section
    id="EstimateRental"
    :style="{ backgroundImage: `url(${bg})` }"
    :class="{ docraptor: $route.name == 'docraptor' }"
  >
    <div class="rental" v-if="rental">
      <h2>
        Rental Fee
        <div class="line"></div>
      </h2>
      <h1>{{ rental.sectionTitle }}</h1>
      <div
        class="rental-description"
        v-if="rental.description"
        v-html="rental.description"
      ></div>
      <p v-else>
        Rental fee includes a 4 hour event, 2 hour load-in, and 2 hour load-out.
        Maintenance, Guest Experience, and Set-up / Use of Stadium-owned tables,
        chairs, and linens are included and subject to availability and the
        discretion of the venue.
      </p>
      <div class="rental-divider"></div>
      <div class="rental-bottom">
        <h4>Rental Fee Subtotal</h4>
        <article>{{ rental.rentalFee | currency }}</article>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EstimateRental",
  data() {
    return {
      rental: null,
      bg: null,
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "105") {
          this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
        }
        if (page.id === "109") {
          // window.console.log(page.copy)
          this.rental = JSON.parse(page.copy);
        }
      });
    }

    if (this.$route.name === "docraptor") {
      setTimeout(() => {
        let pages = this.$store.state.epitch.ePitch.pages;

        pages.forEach((page) => {
          if (page.id === "105") {
            this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
          }
          if (page.id === "109") {
            // window.console.log(page.copy)
            this.rental = JSON.parse(page.copy);
          }
        });
      }, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
#EstimateRental {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/images/Custom/why-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.84) 48%,
      rgba(0, 0, 0, 0) 89%
    );
  }
  @media (max-width: $lg) {
    min-height: auto;
  }
  .rental {
    display: flex;
    flex-direction: column;
    max-width: 554px;
    margin-left: 316.6px;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;
    @media (max-width: $lg) {
      margin: 0;
      height: auto;
      padding: 40px 0 53px 44px;
      width: 80%;
    }
    @media (max-width: $md) {
      padding-left: 50px;
      width: 90%;
    }
    @media (max-width: $sm) {
      padding-left: 20px;
      width: 95%;
    }
    h1 {
      font-family: "ArtegraSans-Bold";
      font-size: 35px;
      color: #ffffff;
      letter-spacing: 2.19px;
      line-height: 30px;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media (max-width: $lg) {
        font-size: 18px;
        letter-spacing: 1.12px;
        line-height: 22px;
      }
    }
    h2 {
      font-family: "ArtegraSans-Bold";
      font-size: 26px;
      color: #ffffff;
      letter-spacing: 1.62px;
      line-height: 25px;
      text-transform: uppercase;
      width: fit-content;
      @media (min-width: $lg) {
        display: none;
      }
      .line {
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
        margin: 20px 0;
        @media (min-width: $lg) {
          display: none;
        }
      }
    }
    &-description {
      font-family: "ArtegraSans-Regular";
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1.07px;
      line-height: 23px;
      margin-bottom: -15px;
      @media (max-width: $lg) {
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 22px;
      }
    }
    p {
      font-family: "ArtegraSans-Regular";
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 1.07px;
      line-height: 23px;
      @media (max-width: $lg) {
        font-size: 15px;
        letter-spacing: 1px;
        line-height: 22px;
      }
    }
    &-divider {
      height: 2px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
      margin-top: 32px;
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 59px;
      padding: 0 16px 0 13px;
      background: rgba(0, 0, 0, 0.3);
      @media (max-width: $lg) {
        flex-direction: column;
        align-items: flex-start;
        padding: 23px 0 0 13px;
        height: auto;
      }
      h4 {
        font-family: "ArtegraSans-Bold";
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0.94px;
        line-height: 30px;
        text-transform: uppercase;
      }
      article {
        font-family: "ArtegraSans-Light";
        font-size: 28px;
        color: #ffffff;
        letter-spacing: 1.75px;
        text-align: right;
        line-height: 30px;
      }
    }
  }
}
@media print, screen {
  #EstimateRental.docraptor {
    background-image: url("~@/assets/images/Custom/why-pdf.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 900px;
    width: auto;
    align-items: flex-start;
    width: 100%;

    .rental {
      padding: 120px 100px 30px 100px;
      width: 100%;
      max-width: none;
      margin-left: auto;
    }

    .rental-bottom {
      display: block;
      padding: 10px;
      h4 {
        margin: 0;
        padding: 0;
      }
      article {
        margin: 0;
        padding: 0;
        margin-top: 5px;
        text-align: left;
      }
    }
  }
}
</style>