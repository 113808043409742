<template>
  <div v-if="$route.name !== 'docraptor'">
    <IntroVideo v-if="$route.name === 'id1'" @skipVideo="skipVideo" @setLoading="setLoading" />

    <transition name="fade" v-if="loading">
      <SiteLoader />
    </transition>
    
    <transition-group name="fade" v-else>
      <TopNav key="2" />
      <div key="3" id="router-view-container">
        <SideBarNav v-if="global.windowWidth && global.windowWidth > 992"  />
        <transition
          name="fade"
          mode="out-in"
        >
          <router-view />
        </transition>
      </div>
    </transition-group>

  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import SiteLoader from "@/components/global/SiteLoader";
import SideBarNav from "@/components/Nav/SideBarNav";

import TopNav from "@/components/Nav/TopNav";
import IntroVideo from "@/components/Layout/IntroVideo";
import { mapActions, mapState } from "vuex";

export default {
  name: "Layout",
  components: {
    SiteLoader,
    IntroVideo,
    TopNav,
    SideBarNav
  },
  computed: {
    ...mapState(["global", "epitch"])
  },
  data() {
    return {
      loaderFinished: false,
      loading: true,
      windowWidth: window.innerWidth,
      showIntroVideo: true
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => {
        //window.console.log("inner width", window.innerWidth);
        this.setWindowWidth(window.innerWidth);
      },
      false
    );
    if(this.$route.name !== 'id1'){
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  },
  methods: {
    isMobile(width) {
      return width.windowWidth < 992;
    },
    skipVideo(payload) {
      if (payload == true) {
        this.loaderFinished = true;

        setTimeout(() => {
          this.showIntroVideo = false;
          if(this.userInfoLoaded){
            this.loaderFinished = false;
          }
        }, 500);
      }
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    ...mapActions(["setWindowWidth"])
  }
};
</script>

<style lang="scss" scoped>
#router-view-container {
  display: flex;
  flex-direction: row;
  &.home {
    padding-top: 0px;
  }
}
/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>