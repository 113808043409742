<template>
  <section
    id="WhyAllegiantStadium"
    :style="{ backgroundImage: `url(${bg})` }"
    :class="{ docraptor: $route.name == 'docraptor' }"
  >
    <div class="why">
      <img :src="clientLogo" alt="bridgestone" v-if="clientLogo" />
      <h1>
        {{ why.title }}
        <div class="why-divider"></div>
      </h1>
      <div class="table" v-html="why.copy"></div>
    </div>
    <CustomMobile class="mobile" v-if="$route.name !== 'docraptor'" />
  </section>
</template>

<script>
import CustomMobile from "@/components/sections/CustomTab/CustomMobile";

export default {
  name: "WhyAllegiantStadium",
  components: {
    CustomMobile,
  },
  data() {
    return {
      why: {},
      clientLogo: null,
      bg: null,
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "105") {
          this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
        }
        if (page.id === "106") {
          this.clientLogo = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
          this.why = page;
        }
      });
    }

    if (this.$route.name === "docraptor") {
      setTimeout(() => {
        let pages = this.$store.state.epitch.ePitch.pages;
        pages.forEach((page) => {
          if (page.id === "105") {
            this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
          }
          if (page.id === "106") {
            this.clientLogo = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
            this.why = page;
          }
        });
      }, 3000);
    }
  },
};
</script>

<style lang="scss" scoped>
#WhyAllegiantStadium {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/images/Custom/why-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: $lg) {
    min-height: auto;
    background: url("~@/assets/images/MobileBG/Custom-bg.jpg") center center;
    background-size: cover;
    background-attachment: fixed;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.84) 48%,
      rgba(0, 0, 0, 0) 89%
    );
  }
  .why {
    height: 100%;
    width: 100%;
    padding: 190px 0 0 345px;
    position: relative;
    z-index: 1;
    @media (max-width: $lg) {
      height: auto;
      padding: 148px 0 47px 100px;
    }
    @media (max-width: $md) {
      padding-left: 50px;
    }
    @media (max-width: $sm) {
      padding-left: 20px;
    }

    img {
      width: 200px;
      @media (max-width: $md) {
        width: 40%;
      }
    }
    h1 {
      font-family: "ArtegraSans-Bold";
      font-size: 35px;
      color: #ffffff;
      letter-spacing: 2.19px;
      line-height: 30px;
      text-transform: uppercase;
      width: auto;
      display: inline-block;
      margin-top: 87.6px;
      @media (max-width: $lg) {
        font-size: 26px;
        letter-spacing: 1.62px;
        line-height: 25px;
      }
      .why-divider {
        height: 2px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
        margin: 21px 0 36px 0;
      }
    }
    .table {
      font-family: "ArtegraSans-Light";
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 1.25px;
      line-height: 27px;
      max-width: 625px;
      margin-left: -20px;
      @media (max-width: $md) {
        font-size: 12px;
        letter-spacing: 0.8px;
        line-height: 19px;
      }
    }
  }
  .mobile {
    @media (min-width: 993px) {
      display: none;
    }
  }
}
@media print, screen {
  #WhyAllegiantStadium.docraptor {
    //min-height: 100vh;
    background-image: url("~@/assets/images/Custom/why-pdf.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 900px;
    width: 100%;
    .why {
      padding: 120px 100px;
    }
    ul {
      li {
        font-family: "ArtegraSans-Light";
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 1.25px;
        line-height: 27px;
        margin-bottom: 10px;
      }
    }
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    //height: 900px;
    width: 100%;
  }
}
</style>

<style lang="scss">
#WhyAllegiantStadium {
  min-height: 900px;
  .why {
    width: 100%;
    max-width: none;
    .table {
      ul li {
        margin-bottom: 10px;
      }
    }
  }
}
</style>