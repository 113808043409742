<template>
  <section
    id="FoodBeverage"
    :style="{ backgroundImage: `url(${bg})` }"
    :class="{ docraptor: $route.name == 'docraptor' }"
  >
    <div class="food" v-if="food">
      <div class="food-title-bar">
        <article class="item description">Description</article>
        <article class="item qty">Qty</article>
        <article class="item price">Price</article>
        <article class="item notes">Notes</article>
        <article class="item total">Total</article>
      </div>
      <div class="food-content">
        <div class="header first">
          <p>Silver & Black Hospitality</p>
          <div class="header-line"></div>
        </div>
        <div class="header first" v-if="food.minimumAmount">
          <p>{{ food.minimumAmount }}</p>
        </div>
        <div
          class="info"
          v-for="(foodBeverage, index) in food.foodBeverages"
          :key="'foodBeverage' + index"
        >
          <p class="info-one">{{ foodBeverage.description }}</p>
          <p class="info-two">{{ foodBeverage.quantity }}</p>
          <p class="info-three">{{ foodBeverage.price | currency }}</p>
          <p class="info-four">{{ foodBeverage.notes }}</p>
          <p class="info-five">
            {{ total(foodBeverage.quantity, foodBeverage.price) | currency }}
          </p>
        </div>
        <div class="header">
          <p>Labor</p>
          <div class="header-line"></div>
        </div>
        <div
          class="info"
          v-for="(labor, index) in food.labor"
          :key="'labor' + index"
        >
          <p class="info-one">{{ labor.description }}</p>
          <p class="info-two">{{ labor.quantity }}</p>
          <p class="info-three">{{ labor.price | currency }}</p>
          <p class="info-four">{{ labor.notes }}</p>
          <p class="info-five">
            {{ total(labor.quantity, labor.price) | currency }}
          </p>
        </div>
        <div class="header">
          <p>Food & Beverage Rentals</p>
          <div class="header-line"></div>
        </div>
        <div class="info">
          <p class="info-four">{{ food.foodBeveralRentalNotes }}</p>
          <p class="info-five" v-if="food.foodBeveralRentalTotal === ''">TBD</p>
          <p class="info-five" v-else>
            {{ food.foodBeveralRentalTotal | currency }}
          </p>
        </div>
        <div class="header other">
          <p>Other</p>
          <div class="header-line"></div>
        </div>
        <div class="info">
          <p class="info-one">{{ adminFeeTitle }}</p>
          <p class="info-five">{{ food.adminFeeTotal | currency }}</p>
        </div>
        <div class="info">
          <p class="info-one">State Tax Food & Beverage</p>
          <p class="info-five">{{ food.stateTaxTotal | currency }}</p>
        </div>
        <div class="food-content-divider"></div>
        <div class="food-content-subtotal">
          <div class="top">
            <h3>FOOD & BEVERAGE SUBTOTAL</h3>
            <h1>{{ subTotal() | currency }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FoodBeverage",
  data() {
    return {
      food: null,
      bg: null,
      adminFeeTitle: null,
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "105") {
          this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
        }
        if (page.id === "110") {
          this.food = JSON.parse(page.copy);
          this.adminFeeTitle = this.food.adminFeeTitle;
        }
      });
    }

    if (this.$route.name === "docraptor") {
      setTimeout(() => {
        let pages = this.$store.state.epitch.ePitch.pages;

        pages.forEach((page) => {
          if (page.id === "105") {
            this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
          }
          if (page.id === "110") {
            this.food = JSON.parse(page.copy);
          }
        });
      }, 3000);
    }
  },
  methods: {
    total(quantity, price) {
      let mult = 0;

      mult = quantity * price.replace("$", "").replace(/,/g, "");

      return mult;
    },
    subTotal() {
      let sum = 0;
      for (let i = 0; i < this.food.foodBeverages.length; i++) {
        sum += isNaN(
          parseFloat(
            this.food.foodBeverages[i].quantity *
              this.food.foodBeverages[i].price
                .replace("$", "")
                .replace(/,/g, "")
          )
        )
          ? 0
          : parseFloat(
              this.food.foodBeverages[i].quantity *
                this.food.foodBeverages[i].price
                  .replace("$", "")
                  .replace(/,/g, "")
            );
      }
      for (let i = 0; i < this.food.labor.length; i++) {
        sum += isNaN(
          parseFloat(
            this.food.labor[i].quantity *
              this.food.labor[i].price.replace("$", "").replace(/,/g, "")
          )
        )
          ? 0
          : parseFloat(
              this.food.labor[i].quantity *
                this.food.labor[i].price.replace("$", "").replace(/,/g, "")
            );
      }
      sum += isNaN(
        parseFloat(
          String(
            this.food.foodBeveralRentalTotal.replace("$", "").replace(/,/g, "")
          )
        )
      )
        ? 0
        : parseFloat(
            String(
              this.food.foodBeveralRentalTotal
                .replace("$", "")
                .replace(/,/g, "")
            )
          );
      sum += isNaN(
        parseFloat(
          String(this.food.adminFeeTotal.replace("$", "").replace(/,/g, ""))
        )
      )
        ? 0
        : parseFloat(
            String(this.food.adminFeeTotal.replace("$", "").replace(/,/g, ""))
          );
      sum += isNaN(
        parseFloat(
          String(this.food.stateTaxTotal.replace("$", "").replace(/,/g, ""))
        )
      )
        ? 0
        : parseFloat(
            String(this.food.stateTaxTotal.replace("$", "").replace(/,/g, ""))
          );

      return sum;
    },
  },
};
</script>

<style lang="scss" scoped>
#FoodBeverage {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url("~@/assets/images/Custom/why-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
  }
  .food {
    padding: 183px 60px 0 333px;
    width: 100%;
    position: relative;
    z-index: 1;
    padding-bottom: 100px;
    &-title-bar {
      position: relative;
      height: 34px;
      width: 100%;
      background-image: linear-gradient(0deg, #d5d5d5 0%, #ffffff 100%);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px 0 13px;

      .item {
        position: absolute;
        font-family: "ArtegraSans-Bold";
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.75px;
        line-height: 30px;
        text-transform: uppercase;
      }
      .description {
        left: 13px;
      }
      .qty {
        left: 20%;
      }
      .price {
        left: 27%;
      }
      .notes {
        left: 37%;
      }
      .total {
        right: 10px;
      }
    }
    &-content {
      position: relative;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      padding: 13px 0 14px 0;
      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 10px 7px 13px;
        p {
          font-family: "ArtegraSans-Regular";
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 21px;
          margin-right: 20px;
        }
        &-line {
          height: 1px;
          background: #ffffff;
          flex-grow: 1;
        }
      }
      .first {
        padding-top: 0;
      }
      .other {
        padding-top: 35px;
      }
      .info {
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: "ArtegraSans-Light";
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 20px;
        &-one {
          padding-left: 13px;
        }
        &-two {
          position: absolute;
          left: 20%;
        }
        &-three {
          position: absolute;
          left: 27%;
        }
        &-four {
          position: absolute;
          left: 37%;
          padding-right: 100px;
        }
        &-five {
          position: absolute;
          right: 10px;
        }
      }
      &-divider {
        height: 2px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
        margin: 17px 0;
      }
      &-subtotal {
        padding-left: 13px;
        .top {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0 10px 0 0;
          h3 {
            font-family: "ArtegraSans-Bold";
            font-size: 15px;
            color: #ffffff;
            letter-spacing: 0.94px;
            line-height: 30px;
          }
          h1 {
            font-family: "ArtegraSans-Light";
            font-size: 28px;
            color: #ffffff;
            letter-spacing: 1.75px;
            text-align: right;
            line-height: 30px;
          }
        }
        p {
          font-family: "ArtegraSans-Light";
          font-size: 13px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }
  }
}
@media print, screen {
  #FoodBeverage.docraptor {
    background-image: url("~@/assets/images/Custom/why-pdf.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 900px;
    width: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;

    &:before {
      min-height: 100vh;
      height: 100vh;
      display: none;
    }

    .food {
      padding: 70px 100px;
      width: 100%;
    }
  }
}
</style>