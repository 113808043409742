import axios from "axios"

const state = {
    userInfo: {
        placeholderData: true,
        firstName: 'Sports Fan',
        lastName: '',
        year: 'XXXX',
        repName: 'Rep Name',
        repEmail: 'ref@team.com',
        repPhone: '(000)-000-0000',

    },
    loadedItems: {
        images: false,
        video: false,
        userInfo: false,
        fonts: false
    },
    allUserAccs: [],
    windowWidth: window.innerWidth
}

const getters = {
    getUserInfo: (state) => state.userInfo
}


const actions = {
    async fetchUserInfo({ commit }) {
        const purl = location.pathname.split('/').pop();
        const response = await axios.post(process.env.VUE_APP_BASE_URL + '/api/router/Users.php', {
            action: "getUserByPurl",
            prefix: "XXXXX",
            purl: purl,
            timestamp: new Date()
        })

        commit('setUserInfo', response ? response.data : 'fetchUserInfoFailed');
        commit('setLoadedItem', {
            loadedProperty: 'userInfo',
            isLoaded: true
        });
    },
    async setLoadedItem({ commit }, payload) {
        commit('setLoadedItem', payload)
    },
    async setUserInfoObject({ commit }, payload) {
        commit('setUserInfoObject', payload)
    },
    async setWindowWidth({ commit }, payload) {
        commit('setWindowWidth', payload)
    }
}

const mutations = {
    setUserInfo: (state, payload) => {
        if (!payload.output || payload.output.length < 1) return;
        let userInfo = payload.output[0];
        state.userInfo = userInfo;
        state.allUserAccs = payload.output;

    },
    setUserInfoObject: (state, payload) => {
        if (!payload) return;
        state.userInfo = payload;
    },
    setLoadedItem: (state, payload) => {
        state.loadedItems = {
            ...state.loadedItems,
            [payload.loadedProperty]: payload.isLoaded
        }
    },
    setWindowWidth: (state, payload) => {
        state.windowWidth = payload;
    }
}


export default {
    state,
    getters,
    actions,
    mutations
}