<template>
  <div id="docraptorWrapper" class="sectionWrapper">
    <div class="section pdfBg" v-for="(page, index) in pages" :key="index">
      <div v-for="(pdfImg, pdfImgIndex) in page.pdfBgArr" :key="pdfImgIndex">
        <img class="sectionBg" :src="require(`@/assets/docraptor/${pdfImg}`)" />
        <!-- <div class="title">{{page.title}}</div> -->
        <!-- <div class="pageNum">{{index + 1}}</div> -->
      </div>
    </div>

    <div class="externalSection" v-if="WhyAllegiantStadium">
      <WhyAllegiantStadium />
      <div class="title">
        {{customTabTitle}}
        <span>&bull;</span> Why Allegiant Stadium
      </div>
      <!-- <div class="pageNum"></div> -->
    </div>

    <div class="externalSection" v-if="Overview">
      <Overview />
      <div class="title">
        {{customTabTitle}}
        <span>&bull;</span> Estimate
        <span>&bull;</span> Overview
      </div>
      <!-- <div class="pageNum"></div> -->
    </div>

    <div class="externalSection" v-if="RentalFee">
      <RentalFee />
      <div class="title">
        {{customTabTitle}}
        <span>&bull;</span> Estimate
        <span>&bull;</span> Rental Fee
      </div>
      <!-- <div class="pageNum"></div> -->
    </div>

    <div class="externalSection" v-if="FoodBeverage">
      <FoodBeverage />
      <div class="title">
        {{customTabTitle}}
        <span>&bull;</span> Estimate
        <span>&bull;</span> Silver & Black Hospitality
      </div>
      <!-- <div class="pageNum"></div> -->
    </div>

    <div class="externalSection" v-if="EventExpenses">
      <EventExpenses />
      <div class="title">
        {{customTabTitle}}
        <span>&bull;</span> Estimate
        <span>&bull;</span> Event Expense
      </div>
      <!-- <div class="pageNum"></div> -->
    </div>

    <div class="externalSection" v-if="SuggestedEnhancement">
      <SuggestedEnhancement />
      <div class="title">
        {{customTabTitle}}
        <span>&bull;</span> Estimate
        <span>&bull;</span> Suggested Enhancement
      </div>
      <!-- <div class="pageNum"></div> -->
    </div>

    <div class="externalSection">
      <div class="externalSectionBg">
        <img :src="require('@/assets/docraptor/thank-you-bg.jpg')" />
      </div>
      <div class="repName">{{repName}}</div>
      <div class="repEmail">{{repEmail}}</div>
    </div>
  </div>
</template>

<script>
import WhyAllegiantStadium from "@/components/sections/CustomTab/WhyAllegiantStadium/WhyAllegiantStadium";
import Overview from "@/components/sections/CustomTab/Estimate/Overview";
import RentalFee from "@/components/sections/CustomTab/Estimate/RentalFee";
import FoodBeverage from "@/components/sections/CustomTab/Estimate/FoodBeverage";
import EventExpenses from "@/components/sections/CustomTab/Estimate/EventExpenses";
import SuggestedEnhancement from "@/components/sections/CustomTab/Estimate/SuggestedEnhancement";

import { mapState } from "vuex";

export default {
  name: "Docraptor",
  components: {
    WhyAllegiantStadium,
    Overview,
    RentalFee,
    FoodBeverage,
    EventExpenses,
    SuggestedEnhancement,
  },
  data() {
    return {
      pages: [],
      extPageNum: null,
      customTabTitle: null,
      repName: null,
      repEmail: null,
      WhyAllegiantStadium: false,
      Overview: false,
      RentalFee: false,
      FoodBeverage: false,
      EventExpenses: false,
      SuggestedEnhancement: false
    };
  },
  methods: {
    traverseDisplay(sections) {
      for (let i = 0; i < sections.length; i++) {
        if (typeof sections[i].pdfBg !== "undefined") {
          let pdfBgArr = sections[i].pdfBg.split(",");

          this.pages.push({
            title: sections[i].title,
            pdfBg: sections[i].pdfBg,
            pdfBgArr: pdfBgArr,
          });
        }
        if (typeof sections[i].subsections !== "undefined") {
          this.traverseDisplay(sections[i].subsections);
        }
      }

      return this.pages.length;
    },
    displayPageNumberToExtSection(pageNumber) {
      let externalSectionPageNums = document.querySelectorAll(
        ".externalSection .pageNum"
      );
      
      externalSectionPageNums.forEach((section, index) => {
        section.innerHTML = pageNumber + index + 1;
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        let sections = this.$store.state.epitch.sections;
        window.console.log('sections', sections);
        this.extPageNum = this.traverseDisplay(sections);

        let repInfo = this.$store.state.epitch.ePitch.rep;
        this.repName = repInfo.first;
        this.repEmail = repInfo.email;

        let pages = this.$store.state.epitch.ePitch.pages;

        pages.forEach((page) => {
          if (page.id === "105") {
            this.customTabTitle = page.title;
          }

          if(page.id == "106"){
            this.WhyAllegiantStadium = true;
          }
          if(page.id == "108"){
            this.Overview = true;
          }
          if(page.id == "109"){
            this.RentalFee = true;
          }
          if(page.id == "110"){
            this.FoodBeverage = true;
          }
          if(page.id == "111"){
            this.EventExpenses = true;
          }
          if(page.id == "112"){
            this.SuggestedEnhancement = true;
          }
        });
      }, 2000);

      setTimeout(() => {
        this.displayPageNumberToExtSection(this.extPageNum);
      }, 4000);
    });
  },
};
</script>

<style lang="scss">
@media print {
  body {
    background: transparent !important;
  }
}
@media print, screen {
  @page { 
    size: 16.66in 9.375in;
    margin: 0; // full-bleed
  }
  #docraptorWrapper {
    background-color: #000;
    .externalSection {
      //page-break-inside: avoid;
      page-break-before: always;
      position: relative;
      min-height: 100vh;

      .externalSectionBg{
        img{
          width: 100%;
        }
      }
      .pageNum {
        font-family: ArtegraSans-Bold;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0.88px;
        line-height: 30px;
        position: absolute;
        left: 30px;
        bottom: 30px;
      }
      .title {
        position: absolute;
        top: 30px;
        left: 30px;
        font-family: ArtegraSans-Bold;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0.88px;
        line-height: 30px;
        text-transform: uppercase;
        span {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .repName {
        font-family: ArtegraSans-Bold;
        font-size: 17px;
        color: #ffd200;
        letter-spacing: 1px;
        line-height: 17px;
        text-transform: uppercase;
        position: absolute;
        bottom: 150px;
        left: 75px;
      }
      .repEmail {
        font-family: ArtegraSans-Regular;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 1.07px;
        line-height: 23px;
        position: absolute;
        bottom: 100px;
        left: 75px;
      }
    }
    .section {
      page-break-inside: avoid;
      margin: auto;
      width: 100%;
      height: auto;
      position: relative;

      .sectionBg {
        width: 100%;
      }
      .title {
        position: absolute;
        top: 30px;
        left: 30px;
        font-family: ArtegraSans-Bold;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0.88px;
        line-height: 30px;
        text-transform: uppercase;
      }
      .pageNum {
        font-family: ArtegraSans-Bold;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0.88px;
        line-height: 30px;
        position: absolute;
        left: 30px;
        bottom: 30px;
      }
    }
  }
}
</style>