import axios from "axios";
import router from "@/router";
import { navData } from "@/data/navData";
const state = {
  url: "https://salesdeck.allegiantstadium.com/",
  ePitch: {},
  summary: {},
  introVideo: {},
  sections: {},
};

const getters = {
  getEpitch: (state) => state.ePitch,
  getSummary: (state) => state.summary,
  getSections: (state) => state.sections,
};

const actions = {
  async fetchEpitch({ commit }) {
    const url = location.pathname.split("/");
    let purl = url[1];
    let mode = "live";

    if (purl && purl.includes("preview.")) {
      purl = purl.replace("preview.", "");
      mode = "preview";
    }

    if (typeof purl === "undefined") {
      purl = "admin.0000";
    }
    axios
      .get(
        "https://salesdeck.allegiantstadium.com/epitchApi/SiteEndpoint.php?purl=" +
          purl +
          "&d=" +
          new Date().getTime()
      )
      .then(function(response) {
        if (response.data.status == "ok") {
          response.data.site.mode = mode;
          response.data.site.purl = purl;

          let pages = response.data.site.pages;
          let sections = combineLocalNav(pages);

          sections = sortSections(sections);

          //window.console.log('Sorted sections', sections)

          if (mode === "live") {
            // Track initial visit - Creates presentationId and sessionId
            axios
              .post(
                `https://salesdeck.allegiantstadium.com/epitchApi/EpitchLiteTracking.php`,
                {
                  method: "visit",
                  purl: purl,
                }
              )
              .then(function(response) {
                if (response.data.status == "ok") {
                  //the following two variables will be declared globally so I can use them again later in subsequent requests
                  window.presentationId = response.data.presentationId;
                  window.sessionDbId = response.data.sessionDbId;

                  window.console.log(window.presentationId);
                  window.console.log(window.sessionDbId);
                }
              });
          }
          if (sections) {
            addRoutesFromSections(sections);
          }

          commit("setPurl", purl);
          commit("setSections", sections);
          commit("setEpitch", response.data.site);
        } else {
          console.log("err", response.data.status);
          //this is an error in my endpoint, or it could also be incorrectly sent information
        }
      });
  },
};

const mutations = {
  setEpitch: (state, payload) => {
    state.ePitch = payload;
  },
  setPurl: (state, payload) => {
    state.purl = payload;
  },
  setSummary: (state, payload) => {
    state.summary = payload;
  },
  setIntroVideo: (state, payload) => {
    state.introVideo = payload;
  },
  setSections: (state, payload) => {
    if (!payload) return;
    state.sections = payload;
  },
};

function sortSections(pages) {
  let sections = [];

  pages
    .filter((page) => {
      return page.to;
    })
    .map((rootPage) => {
      sections.push({
        ...rootPage,
        subsections: attachSubsections(pages, rootPage),
      });
    });
  return sections;
}
function attachSubsections(pages, rootPage) {
  let subsections = pages.filter((page) => page.parent == rootPage.id);

  subsections = subsections.map((subsection) => {
    let nestedSubsections = pages.filter(
      (subsectionPage) => subsectionPage.parent == subsection.id
    );
    if (nestedSubsections && nestedSubsections.length > 0) {
      subsection.subsections = nestedSubsections;
    }
    return subsection;
  });
  return subsections;
}
function addRoutesFromSections(sections) {
  //handles three level deep nesting. Any further would require a recursive method as the code would become very ugly.
  let newRoutes = [];
  //window.console.log('sections',sections);
  sections.forEach((section) => {
    const hasSecondLevelSubsections =
      section.subsections && section.subsections.length > 0;

    if (!hasSecondLevelSubsections) {
      newRoutes.push({
        path: section.to,
        name: "id" + section.id,
        meta: {
          epitchId: section.id,
        },
        title: section.title,
        component: () =>
          import(
            `@/components/sections/${section.rootComponent}/${section.rootComponent}`
          ),
        index: 2,
      });
    } else {
      const redirectUrlHasThirdLevel =
        section.subsections[0].subsections &&
        section.subsections[0].subsections.length > 0;
      let firstRedirectUrl = "";
      let hasRedirect = section.hasRedirect;
      if (redirectUrlHasThirdLevel) {
        firstRedirectUrl =
          section.to +
          section.subsections[0].subroute +
          section.subsections[0].subsections[0].subroute;
      } else {
        hasRedirect = true;
        firstRedirectUrl = section.to + section.subsections[0].subroute;
      }

      if (hasRedirect) {
        newRoutes.push({
          path: section.to,
          redirect: firstRedirectUrl,
          name: "id" + section.id,
          meta: {
            epitchId: section.id,
          },
          title: section.title,
        });
      }

      section.subsections.forEach((subsection) => {
        const hasThirdLevelSubsections =
          subsection.subsections && subsection.subsections.length > 0;
        if (!hasThirdLevelSubsections || subsection.ignoreRouteSkip) {
          if (subsection.ignoreRouteSkip) {
            //window.console.log(`@/components/sections/${section.rootComponent}/${subsection.component}/${subsection.component}`)
          }
          newRoutes.push({
            path: section.to + subsection.subroute,
            name: "subid" + subsection.id,
            meta: {
              epitchId: subsection.id,
            },
            title: subsection.title,
            component: () =>
              import(
                `@/components/sections/${section.rootComponent}/${subsection.component}/${subsection.component}`
              ),
            index: 3,
          });
        }

        if (hasThirdLevelSubsections) {
          if (!subsection.ignoreRouteSkip) {
            newRoutes.push({
              path: section.to,
              redirect: firstRedirectUrl,
              name: "id" + section.id,
              meta: {
                epitchId: section.id,
              },
              title: section.title,
              component: () =>
                import(
                  `@/components/sections/${section.rootComponent}/${section.rootComponent}`
                ),
              index: 2,
            });
          }

          subsection.subsections.forEach((nestedSubsection) => {
            newRoutes.push({
              path:
                section.to + subsection.subroute + nestedSubsection.subroute,
              name: "nsubid" + nestedSubsection.id,
              meta: {
                epitchId: nestedSubsection.id,
              },
              title: nestedSubsection.title,
              component: () =>
                import(
                  `@/components/sections/${section.rootComponent}/${subsection.component}/${nestedSubsection.component}`
                ),
              index: 4,
            });
          });
        }
      });
    }
  });

  //window.console.log('routes added: ', newRoutes.length);
  // window.console.log("newroutes: ", newRoutes);
  router.options.routes.push(...newRoutes);
  router.addRoutes(newRoutes);
}

function combineLocalNav(sections) {
  return sections.map((section) => {
    const localSectionData = navData().localData["id" + section.id];

    if (localSectionData) {
      section = {
        ...section,
        ...localSectionData,
      };
    }
    return section;
  });
}
export default {
  state,
  getters,
  actions,
  mutations,
};
