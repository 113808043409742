<template>
  <section id="CustomMobile">
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <Overview />
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <RentalFee />
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <FoodBeverageMobile />
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <EventExpensesMobile />
    <div class="dividerOne"></div>
    <div class="dividerTwo"></div>
    <SuggestedEnhancementMobile />
  </section>
</template>

<script>
  import Overview from '@/components/sections/CustomTab/Estimate/Overview'
  import RentalFee from '@/components/sections/CustomTab/Estimate/RentalFee'
  import FoodBeverageMobile from '@/components/sections/CustomTab/Estimate/FoodBeverageMobile'
  import EventExpensesMobile from '@/components/sections/CustomTab/Estimate/EventExpensesMobile'
  import SuggestedEnhancementMobile from '@/components/sections/CustomTab/Estimate/SuggestedEnhancementMobile'

  export default {
    name: "CustomMobile",
    components: {
      Overview,
      RentalFee,
      FoodBeverageMobile,
      EventExpensesMobile,
      SuggestedEnhancementMobile
    }
  }
</script>

<style lang="scss" scoped>
  #CustomMobile {
    width: 100vw;
    height: auto;
    .dividerOne {
      width: 100%;
      height: 2px;
      background: #1A1A1A;
      // margin-top: 40px;
    }
    .dividerTwo {
      width: 100%;
      height: 2px;
      background: #2C2C2C;
      // margin-bottom: 40px;
    }
  }
</style>