import Vue from "vue";
import App from "./App.vue";
import VueScrollTo from "vue-scrollto";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import AOS from "aos";
import "aos/dist/aos.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// import { detect } from 'detect-browser';
import store from "./store";
import router from "./router/index.js";
import axios from "axios";
import Vue2Filters from "vue2-filters";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapActions } from "vuex";

library.add(faSpinner);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.config.devtools = true;

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(BootstrapVue);

Vue.use(VueSweetalert2);

Vue.use(Vue2Filters);

/*
// Browser Detection
axios.post(process.env.VUE_APP_BASE_URL + '/api/router/Tracking.php',{
  action: "tracking",
  prefix: "XXXX",
  label: `browser/${detect().name}`
}).catch( () => {
  window.console.log('failed tracking')
});
*/

Vue.directive("ePitchTracking", {
  bind(el, binding) {
    const label = binding.value;

    el.addEventListener("click", () => {
      // axios.post(process.env.VUE_APP_BASE_URL + '/api/router/Tracking.php',{
      //   action: "tracking",
      //   prefix: "XXXX2020",
      //   label: label
      // }).catch( () => {
      //   window.console.log('failed tracking')
      // });
      window.console.log(label);
    });
  },
});

new Vue({
  store,
  methods: {
    ...mapActions(["fetchEpitch"]),
  },
  watch: {
    $route: function(to, from) {
      let epitchId = this.$route.meta.epitchId;

      if (epitchId && window.presentationId && window.sessionDbId) {
        axios.post(
          `https://salesdeck.allegiantstadium.com/epitchApi/EpitchLiteTracking.php`,
          {
            method: "page",
            purl: this.$store.state.epitch.purl,
            presentationId: window.presentationId,
            sessionDbId: window.sessionDbId,
            section: epitchId,
          }
        );
      }
    },
  },

  created() {
    this.fetchEpitch();
    window.addEventListener("load", function() {
      AOS.init();
    });
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
