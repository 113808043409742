<template>
  <div id="primary-nav">
    <div class="p-nav-links" v-if="epitch.sections">
      <div v-for="(link, index) in epitch.sections" :key="'p-nav' + index">
        <div v-if="!link.hideNav" :class="`p-nav-link ${$route && $route.path === link.to ? 'active' : ''}`">
          <router-link :to="link.to">{{link.title}}</router-link>
          <div v-if="$route && $route.path === link.to" class="highlight"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PrimaryNav",
  computed: {
    ...mapState(["epitch", "global"])
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
#primary-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 400px);
  left: 400px;
  background: url('~@/assets/header/nav-repeat.png') scroll repeat-x left top;
  background-size: 1px 128px;
  z-index: 600;
  @media (max-width: $xl) {
    padding-right: 6.2vw;
  }
  /**Adjust this padding for mobile, remove and justify center */
  .p-nav-links {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 74px;
    margin-left: -50px;
    background: transparent;
    .p-nav-link {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 11px 15px;
      a {
        font-family: "ArtegraSans-Bold";
        font-size: 14px;
        color: #787878;
        letter-spacing: 0.88px;
        line-height: 30px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.5s ease-in-out;
        @media (max-width: 1384px) {
          line-height: 18px;
        }
        @media (max-width: $xxl) {
          line-height: 18px;
        }
        &:hover {
          color: #000000;
        }
        &.router-link-active {
          color: #000000;
        }
      }
    }
    .active {
      a {
        color: #000000;
      }
    }
  }
}
</style>