export const navData = () => {
  const localData = {
    id1: {
      id: 1,
      to: "/",
      rootComponent: "VideoIntro",
      hideNav: true,
    },
    id2: {
      id: 2,
      to: "/landing",
      rootComponent: "Landing",
      hideNav: true,
    },
    id3: {
      id: 3,
      to: "/venue",
      rootComponent: "Venue",
    },
    id23: {
      id: 23,
      to: "/eventspaces",
      rootComponent: "EventSpaces",
    },
    id90: {
      id: 90,
      to: "/experiences",
      rootComponent: "Experiences",
    },
    id91: {
      id: 91,
      to: "/foodbeverage",
      rootComponent: "FoodBeverage",
      hasRedirect: true,
    },
    id150: {
      id: 150,
      to: "/sustainability",
      rootComponent: "Sustainability",
    },
    id4: {
      id: 4,
      title: "Venue Overview",
      subroute: "/venue-overview",
      component: "VenueOverview",
    },
    id5: {
      id: 5,
      title: "Who We Are",
      subroute: "/whoweare",
      component: "WhoWeAre",
    },
    id92: {
      id: 92,
      title: "What We Are",
      subroute: "/whatweare",
      component: "WhatWeAre",
    },
    id10: {
      id: 10,
      title: "Where We Are",
      subroute: "/whereweare",
      component: "WhereWeAre",
      ignoreRouteSkip: true,
    },
    id117: {
      id: 117,
      title: "Where We Are",
      subroute: "/lasVegasStrip",
      component: "LasVegasStrip",
      ignoreRouteSkip: true,
    },
    id149: {
      id: 149,
      title: "What We Do",
      subroute: "/whatwedo",
      component: "WhatWeDo",
    },
    id6: {
      id: 6,
      title: "Sales",
      subroute: "/sales",
      component: "Sales",
    },
    id7: {
      id: 7,
      title: "Event Management",
      subroute: "/eventManagement",
      component: "EventManagement",
    },
    id8: {
      id: 8,
      title: "Silver & Black Hospitality",
      subroute: "/fb",
      component: "FB",
    },
    id9: {
      id: 9,
      title: "Game Plan",
      subroute: "/gameplan",
      component: "GamePlan",
    },
    id11: {
      id: 11,
      title: "Bellagio",
      subroute: "/bellagio",
      component: "Bellagio",
    },
    id12: {
      id: 12,
      title: "Caesars Palace",
      subroute: "/caesarspalace",
      component: "CaesarsPalace",
    },
    id13: {
      id: 13,
      title: "Encore & WYNN",
      subroute: "/encorewynn",
      component: "EncoreWynn",
    },
    id14: {
      id: 14,
      title: "Las Vegas Convention Center",
      subroute: "/conventioncenter",
      component: "LasVegasConventionCenter",
    },
    id15: {
      id: 15,
      title: "Luxor",
      subroute: "/luxor",
      component: "Luxor",
    },
    id137: {
      id: 137,
      title: "Paris",
      subroute: "/paris",
      component: "Paris",
    },
    id138: {
      id: 138,
      title: "Harrah’s ",
      subroute: "/harrahs",
      component: "Harrahs",
    },
    id139: {
      id: 139,
      title: "Fontainebleau ",
      subroute: "/fontainebleau",
      component: "Fontainebleau",
    },
    id16: {
      id: 16,
      title: "M Resort Spa Casino",
      subroute: "/spacasino",
      component: "MResortSpaCasino",
    },
    id17: {
      id: 17,
      title: "Mandalay Bay",
      subroute: "/mandalaybay",
      component: "MandalayBay",
    },
    id18: {
      id: 18,
      title: "MGM Grand",
      subroute: "/mgm",
      component: "MGM",
    },
    id19: {
      id: 19,
      title: "New York, New York",
      subroute: "/newyork",
      component: "NewYork",
    },
    id20: {
      id: 20,
      title: "Park MGM",
      subroute: "/parkmgm",
      component: "ParkMGM",
    },
    id21: {
      id: 21,
      title: "Stratosphere",
      subroute: "/stratosphere",
      component: "Stratosphere",
    },
    id22: {
      id: 22,
      title: "The Venetian & The Palazzo",
      subroute: "/venetian",
      component: "Venetian",
    },
    id141: {
      id: 141,
      title: "The Cosmpolitan",
      subroute: "/cosmopolitan",
      component: "Cosmopolitan",
    },
    id142: {
      id: 142,
      title: "Caesars Forum",
      subroute: "/caesarsforum",
      component: "CaesarsForum",
    },
    id118: {
      id: 118,
      title: "Aria",
      subroute: "/aria",
      component: "Aria",
    },
    id120: {
      id: 120,
      title: "Resorts World Las Vegas",
      subroute: "/resortworld",
      component: "ResortWorld",
    },
    id24: {
      id: 24,
      title: "Allegiant Stadium Buyout",
      subroute: "/allegiantstadium",
      component: "AllegiantStadium",
    },
    id30: {
      id: 30,
      title: "The Field",
      subroute: "/field",
      component: "Field",
    },
    id36: {
      id: 36,
      title: "Coors Light Landing",
      subroute: "/coorslightlanding",
      component: "CoorsLightLanding",
    },
    id42: {
      id: 42,
      title: "Raiders Locker Room",
      subroute: "/raiderslockerroom",
      component: "RaidersLockerRoom",
    },
    id48: {
      id: 48,
      title: "Modelo Cantina Club",
      subroute: "/cantinaclub",
      component: "ModeloCantinaClub",
    },
    id54: {
      id: 54,
      title: "Twitch Lounge",
      subroute: "/twitchlounge",
      component: "TwitchLounge",
    },
    id60: {
      id: 60,
      title: "Credit One Club",
      subroute: "/creditoneclub",
      component: "CreditOneClub",
    },
    id66: {
      id: 66,
      title: "Champions Club",
      subroute: "/champions-club",
      component: "RoKitClub",
    },
    id124: {
      id: 124,
      title: "Shift4",
      subroute: "/shift4",
      component: "Shift4",
    },
    id125: {
      id: 125,
      title: "Yaamava Club",
      subroute: "/yaamava-club",
      component: "YaamavaClub",
    },
    id72: {
      id: 72,
      title: "North Endzone Club",
      subroute: "/fieldlevelendzone",
      component: "FieldLevelEndzone",
    },
    id78: {
      id: 78,
      title: "Allegiant Flight Deck North",
      subroute: "/allegiantflightdeck",
      component: "AllegiantFlightDeck",
    },
    id84: {
      id: 84,
      title: "Ford Plaza",
      subroute: "/fordplaza",
      component: "FordPlaza",
    },
    id25: {
      id: 25,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id26: {
      id: 26,
      title: "Venues",
      subroute: "/photos",
      component: "Photos",
    },
    id27: {
      id: 27,
      title: "Sample Floor Plan",
      subroute: "/layout",
      component: "Layout",
    },
    id28: {
      id: 28,
      title: "Event Photos",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id29: {
      id: 29,
      title: "Inspiration",
      subroute: "/inspiration",
      component: "Inspiration",
    },
    id31: {
      id: 31,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id32: {
      id: 32,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id33: {
      id: 33,
      title: "Layouts",
      subroute: "/layout",
      component: "Layout",
    },
    id34: {
      id: 34,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id35: {
      id: 35,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "FieldEventPhotos",
    },
    id37: {
      id: 37,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id38: {
      id: 38,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id39: {
      id: 39,
      title: "Layouts",
      subroute: "/layout",
      component: "Layout",
    },
    id40: {
      id: 40,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "EventPhotos",
    },
    // id41: {
    //   id: 41,
    //   title: "Event Photos",
    //   subroute: "/eventphotos",
    //   component: "EventPhotos",
    // },
    id43: {
      id: 43,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id44: {
      id: 44,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id45: {
      id: 45,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id46: {
      id: 46,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id47: {
      id: 47,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "EventPhotos",
    },
    id49: {
      id: 49,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id50: {
      id: 50,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id51: {
      id: 51,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id52: {
      id: 52,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id53: {
      id: 53,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "EventPhotos",
    },
    id55: {
      id: 55,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id56: {
      id: 56,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id57: {
      id: 57,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id58: {
      id: 58,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id59: {
      id: 59,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "EventPhotos",
    },
    id61: {
      id: 61,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id62: {
      id: 62,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id63: {
      id: 63,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id64: {
      id: 64,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id65: {
      id: 65,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "Inspiration",
    },

    id67: {
      id: 67,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id68: {
      id: 68,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id69: {
      id: 69,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id70: {
      id: 70,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id71: {
      id: 71,
      title: "Event Photos",
      subroute: "/eventphotos",
      component: "EventPhotos",
    },
    id73: {
      id: 73,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id74: {
      id: 74,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id75: {
      id: 75,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id76: {
      id: 76,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id77: {
      id: 77,
      title: "Inspiration",
      subroute: "/inspiration",
      component: "Inspiration",
    },
    id79: {
      id: 79,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id80: {
      id: 80,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id81: {
      id: 81,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id82: {
      id: 82,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id83: {
      id: 83,
      title: "Inspiration",
      subroute: "/inspiration",
      component: "Inspiration",
    },
    id85: {
      id: 85,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id86: {
      id: 86,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id87: {
      id: 87,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id88: {
      id: 88,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id143: {
      id: 143,
      title: "Parking Lots",
      subroute: "/parkinglots",
      component: "ParkingLots",
    },
    id144: {
      id: 144,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id145: {
      id: 145,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id146: {
      id: 146,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id147: {
      id: 147,
      title: "Event Photos",
      subroute: "/eventphots",
      component: "EventPhotos",
    },
    id89: {
      id: 89,
      title: "Inspiration",
      subroute: "/inspiration",
      component: "Inspiration",
    },
    id126: {
      id: 126,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id127: {
      id: 127,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id128: {
      id: 128,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id129: {
      id: 129,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id130: {
      id: 130,
      title: "Inspiration",
      subroute: "/inspiration",
      component: "Inspiration",
    },
    id131: {
      id: 131,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id132: {
      id: 132,
      title: "Photos",
      subroute: "/photos",
      component: "Photos",
    },
    id133: {
      id: 133,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id134: {
      id: 134,
      title: "Field View",
      subroute: "/fieldview",
      component: "FieldView",
    },
    id135: {
      id: 135,
      title: "Inspiration",
      subroute: "/inspiration",
      component: "Inspiration",
    },
    id93: {
      id: 93,
      title: "Branding & Signage",
      subroute: "/brandingSignage",
      component: "BrandingSignage",
    },
    id94: {
      id: 94,
      title: "North Video Boards",
      subroute: "/northVideoBoards",
      component: "NorthVideoBoards",
    },
    id119: {
      id: 119,
      title: "South Video Board",
      subroute: "/southVideoBoard",
      component: "SouthVideoBoard",
    },
    id95: {
      id: 95,
      title: "Ribbon Boards",
      subroute: "/ribbonBoards",
      component: "RibbonBoards",
    },
    id96: {
      id: 96,
      title: "Marquee",
      subroute: "/marquee",
      component: "Marquee",
    },
    id97: {
      id: 97,
      title: "Media Mesh",
      subroute: "/mediaMesh",
      component: "MediaMesh",
    },
    id98: {
      id: 98,
      title: "Stadium Tours",
      subroute: "/stadiumTours",
      component: "StadiumTours",
    },
    id122: {
      id: 122,
      title: "PPK",
      subroute: "/ppk",
      component: "PPK",
    },
    id123: {
      id: 123,
      title: "IMAG",
      subroute: "/imag",
      component: "IMAG",
    },
    id99: {
      id: 99,
      title: "Raiderettes",
      subroute: "/raiderettes",
      component: "Raiderettes",
    },
    id100: {
      id: 100,
      title: "Rusher",
      subroute: "/rusher",
      component: "Rusher",
    },
    id101: {
      id: 101,
      title: "Alumni",
      subroute: "/alumni",
      component: "Alumni",
    },
    id148: {
      id: 148,
      title: "Entertainment",
      subroute: "/entertainment",
      component: "Entertainment",
    },
    id102: {
      id: 102,
      title: "Levy",
      subroute: "/levy",
      component: "Levy",
    },
    id121: {
      id: 121,
      title: "Silver & Black Hospitality",
      subroute: "/silver-black-hospitality",
      component: "SilverBlackHospitality",
    },
    id103: {
      id: 103,
      title: "Meet The Chef",
      subroute: "/meetTheChef",
      component: "MeetTheChef",
    },
    id104: {
      id: 104,
      title: "Service Concepts",
      subroute: "/typesOfService",
      component: "TypesOfService",
    },
    id105: {
      id: 105,
      to: "/customTab",
      rootComponent: "CustomTab",
    },
    id106: {
      id: 106,
      title: "Why Allegiant Stadium",
      subroute: "/whyAllegiantStadium",
      component: "WhyAllegiantStadium",
    },
    id107: {
      id: 107,
      title: "Estimate",
      subroute: "/estimate",
      component: "Estimate",
    },
    id108: {
      id: 108,
      title: "Overview",
      subroute: "/overview",
      component: "Overview",
    },
    id109: {
      id: 109,
      title: "Rental Fee",
      subroute: "/rentalFee",
      component: "RentalFee",
    },
    id110: {
      id: 110,
      title: "Silver & Black Hospitality",
      subroute: "/foodBeverage",
      component: "FoodBeverage",
    },
    id111: {
      id: 111,
      title: "Event Expenses",
      subroute: "/eventExpenses",
      component: "EventExpenses",
    },
    id112: {
      id: 112,
      title: "Suggested Enhancement",
      subroute: "/suggestedEnhancement",
      component: "SuggestedEnhancement",
    },
    id113: {
      id: 113,
      title: "Detailed Event Estimate",
      subroute: "/detailedEventEstimate",
      component: "DetailedEventEstimate",
    },
    id114: {
      id: 114,
      title: "Layout",
      subroute: "/layout",
      component: "Layout",
    },
    id115: {
      id: 115,
      title: "Menu",
      subroute: "/menu",
      component: "Menu",
    },
    id116: {
      id: 116,
      title: "Branding & Signage",
      subroute: "/brandingSignage",
      component: "BrandingSignage",
    },
  };
  return {
    localData,
  };
};
