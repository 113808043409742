<template>
  <section id="FoodBeverageMobile">
    <h2>
      Food & Beverage
      <div class="line"></div>
    </h2>
    <div class="accordion" v-if="food">
      <div class="accordion-item" :class="{ 'is-closed': !foodOpen }">
        <div class="accordion-item-content" @click="foodOpen = !foodOpen">
          <span class="title">Food & Beverage</span>
          <img src="@/assets/icons/Custom/close.svg" :class="{ rotate: foodOpen }" />
        </div>
        <div
          class="accordion-item-description"
          v-for="(foodBeverage, index) in food.foodBeverages"
          :key="'foodBeverages' + index"  
        >
          <div class="accordion-item-description-body">
            <h3>{{ foodBeverage.description }}</h3>
            <div class="line"></div>
            <div class="middle">
              <div class="middle-item">
                <h5>QTY</h5>
                <p>{{ foodBeverage.quantity }}</p>
              </div>
              <div class="middle-item">
                <h5>PRICE</h5>
                <p>{{ foodBeverage.price | currency }}</p>
              </div>
              <div v-if="foodBeverage.notes" class="middle-item">
                <h5>NOTES</h5>
                <p>{{ foodBeverage.notes }}</p>
              </div>
            </div>
            <div class="line"></div>
            <div class="total">
              <div class="total-content">
                <h4>TOTAL</h4>
                <p>{{ total(foodBeverage.quantity, foodBeverage.price) | currency }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item" :class="{ 'is-closed': !laborOpen }">
        <div class="accordion-item-content" @click="laborOpen = !laborOpen">
          <span class="title">Labor</span>
          <img src="@/assets/icons/Custom/close.svg" :class="{ rotate: laborOpen }" />
        </div>
        <div
          class="accordion-item-description"
          v-for="(labor, index) in food.labor"
          :key="'foodBeverages' + index"  
        >
          <div class="accordion-item-description-body">
            <h3>{{ labor.description }}</h3>
            <div class="line"></div>
            <div class="middle">
              <div class="middle-item">
                <h5>QTY</h5>
                <p>{{ labor.quantity }}</p>
              </div>
              <div class="middle-item">
                <h5>PRICE</h5>
                <p>{{ labor.price | currency }}</p>
              </div>
              <div v-if="labor.notes" class="middle-item">
                <h5>NOTES</h5>
                <p>{{ labor.notes }}</p>
              </div>
            </div>
            <div class="line"></div>
            <div class="total">
              <div class="total-content">
                <h4>TOTAL</h4>
                <p>{{ total(labor.quantity, labor.price) | currency }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item" :class="{ 'is-closed': !rentalsOpen }">
        <div class="accordion-item-content" @click="rentalsOpen = !rentalsOpen">
          <span class="title">Food & Beverage Rentals</span>
          <img src="@/assets/icons/Custom/close.svg" :class="{ rotate: rentalsOpen }" />
        </div>
        <div
          class="accordion-item-description"
          >
          <div class="accordion-item-description-body">
          <!-- <h3>Food & Beverage Rentals</h3>
          <div class="line"></div> -->
            <div class="middle">
              <div class="middle-item">
                <h5>NOTES</h5>
                <p>{{ food.foodBeveralRentalNotes }}</p>
              </div>
            </div>
            <div class="line"></div>
            <div class="total">
              <div class="total-content">
                <h4>TOTAL</h4>
                <p>{{ food.foodBeveralRentalTotal | currency }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item" :class="{ 'is-closed': !otherOpen }">
        <div class="accordion-item-content" @click="otherOpen = !otherOpen">
          <span class="title">Other</span>
          <img src="@/assets/icons/Custom/close.svg" :class="{ rotate: otherOpen }" />
        </div>
        <div class="accordion-item-description">
          <div class="accordion-item-description-body">
            <div class="middle">
              <div class="middle-item admin">
                <h5>{{adminFeeTitle}}</h5>
                <p>{{ food.adminFeeTotal | currency }}</p>
              </div>
              <div class="middle-item admin">
                <h5>State Tax Food & Beverages</h5>
                <p>{{ food.stateTaxTotal | currency }}</p>
              </div>
            </div>
            <div class="line"></div>
            <div class="total">
              <div class="total-content">
                <h4>TOTAL</h4>
                <p>{{ otherTotal(food.adminFeeTotal, food.stateTaxTotal) | currency }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="subtotal" v-if="food">
      <div class="top">
        <h3>Food & Beverage Subtotal</h3>
        <h1>{{ subTotal() | currency }}</h1>
      </div>
      <p>
        {{ food.minimumAmount }}
      </p>
    </div>
  </section>
</template>

<script>
  export default {
   name: "FoodBeverageMobile",
   data() {
      return {
        food: null,
        foodOpen: false,
        laborOpen: false,
        rentalsOpen: false,
        otherOpen: false,
        adminFeeTitle: null
      }
    },
    mounted() {
      let pages = this.$store.state.epitch.ePitch.pages;

      pages.forEach(page => {
        if(page.id === "110") {
          this.food = JSON.parse(page.copy);
          this.adminFeeTitle = this.food.adminFeeTitle;
        }
      });
    },
    methods: {
      total (quantity, price) {
        let mult = 0
          
          mult = quantity * parseFloat(price.replace('$',"").replace(/,/g,""))

        return mult
      },
      otherTotal(admin, tax) {
        let add = 0

        add = parseFloat(admin) + parseFloat(tax)

        return add
      },
      subTotal() {
        let sum = 0
        for(let i=0; i<this.food.foodBeverages.length; i++){
          sum += isNaN(
            parseFloat(
              this.food.foodBeverages[i].quantity *
              this.food.foodBeverages[i].price
            )
          )
            ? 0
            : parseFloat(
              this.food.foodBeverages[i].quantity *
              this.food.foodBeverages[i].price
            )
        }
        for(let i=0; i<this.food.labor.length; i++){
          sum += isNaN(
            parseFloat(
              this.food.labor[i].quantity *
              this.food.labor[i].price.replace("$", "").replace(/,/g, "")
            )
          )
            ? 0
            : parseFloat(
              this.food.labor[i].quantity *
              this.food.labor[i].price.replace("$", "").replace(/,/g, "")
            )
        }

        sum += isNaN(
          parseFloat(
            this.food.foodBeveralRentalTotal.replace("$", "").replace(/,/g, "")
          )
        )
          ? 0
          : parseFloat(
            this.food.foodBeveralRentalTotal.replace("$", "").replace(/,/g, "")
          )
        
        sum += isNaN(
          parseFloat(
            this.food.adminFeeTotal.replace("$", "").replace(/,/g, "")
          )
        )
          ? 0
          : parseFloat(
            this.food.adminFeeTotal.replace("$", "").replace(/,/g, "")
          ) 
        sum += isNaN(
          parseFloat(
            this.food.stateTaxTotal.replace("$", "").replace(/,/g, "")
          )
        )
          ? 0
          : parseFloat(
            this.food.stateTaxTotal.replace("$", "").replace(/,/g, "")
          )

          
        return sum
      }
    }
  }
</script>

<style lang="scss" scoped>
  #FoodBeverageMobile {
    position: relative;
    width: 100vw;
    background-image: url('~@/assets/images/Custom/why-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 44px 53px 44px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(90deg, rgba(0,0,0,0.84) 48%, rgba(0,0,0,0.00) 89%);
      z-index: -1;
    }
    @media (max-width: $lg) {
      background-image: none;
    }
    @media (max-width: $md) {
      padding: 0 50px;
    }
    @media (max-width: $sm) {
      padding: 0 20px;
    }
    h2 {
      font-family: 'ArtegraSans-Bold';
      font-size: 26px;
      color: #FFFFFF;
      letter-spacing: 1.62px;
      line-height: 25px;
      text-transform: uppercase;
      width: fit-content;
      @media (min-width: $lg) {
        display: none;
      }
      .line {
        height: 2px;
        background: #FFFFFF;
        box-shadow: 0 0 9px 2px #FFFFFF;
        margin: 20px 0;
        @media (min-width: $lg) {
          display: none;
        }
      }
    }
    .divider {
      height: 2px;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0 0 8px 2px rgba(255,255,255,0.80);
      margin: 17px 0;
    }
    .subtotal {
      margin-bottom: 41px;
      .top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 10px 0 19px;
        h3 {
          font-family: 'ArtegraSans-Bold';
          font-size: 15px;
          color: #FFFFFF;
          letter-spacing: 0.54px;
          line-height: 14px;
          margin-bottom: 5px;
        }
        h1 {
          font-family: 'ArtegraSans-Light';
          font-size: 28px;
          color: #FFFFFF;
          letter-spacing: 1.75px;
          line-height: 30px;
          margin-bottom: 33px;
        }
      }
      p {
        font-family: 'ArtegraSans-Light';
        font-size: 13px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
  }
</style>

<style lang="scss">
  .accordion {
    position: relative;
    &-item {
      min-height: 40px;
      background: rgba(0,0,0,0.40);
      margin-bottom: 5px;
      &-content {
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        .title {
          font-family: 'ArtegraSans-Bold';
          font-size: 15px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 21px;
          text-transform: uppercase;
        }
        img {
          height: 9px;
          transform: rotate(45deg);
          transition: 0.3s ease all;
        }
        .rotate {
          transform: rotate(0deg);
        }
      }
      &-description {
        padding: 21px 20px;
        transition: 0.5s ease all;
          &-body {
            overflow: hidden;
            max-height: 300px;
            transition: 0.5s ease all;
            .line {
              background: #FFFFFF;
              height: 1px;
              width: 100%;
              margin: 10px 0;
            }
            .middle {
              display: flex;
              flex-direction: column;
              &-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                h5 {
                  margin-bottom: 0;
                }
              }
              .admin {
                h5 {
                  width: 172px;
                }
              }
            }
            .total {
              display: flex;
              justify-content: flex-end;
              &-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                p {
                  margin-left: 6px;
                }
              }
            }
            h3 {
              font-family: 'ArtegraSans-Bold';
              font-size: 14px;
              color: #FFFFFF;
              letter-spacing: 0.7px;
              line-height: 10px;
              text-transform: uppercase;
            }
            h4 {
              font-family: 'ArtegraSans-Bold';
              font-size: 12px;
              color: #FFFFFF;
              letter-spacing: 0.6px;
              line-height: 10px;
            }
            h5 {
              font-family: 'ArtegraSans-Bold';
              font-size: 11px;
              color: #FFFFFF;
              letter-spacing: 0.55px;
              line-height: 10px;
              width: 45px;
              margin-right: 11px;
            }
            p {
              font-family: 'ArtegraSans-Light';
              font-size: 12px;
              color: #FFFFFF;
              letter-spacing: 0.75px;
              text-align: right;
            }
        }
      }
    }
    // .is-closed .accordion-item-description-body {
    //   max-height: 0;
    //   overflow: hidden;
    // }
    // .is-closed .accordion-item-description {
    //   padding: 0;
    // }
    .is-closed {
      & .accordion-item-description {
        padding: 0;
      }
      & .accordion-item-description-body {
        max-height: 0;
        overflow: hidden;
      }
    }
  }
</style>