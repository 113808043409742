<template>
  <section
    id="EventExpenses"
    :style="{ backgroundImage: `url(${bg})` }"
    :class="{ docraptor: $route.name == 'docraptor' }"
  >
    <div class="expenses">
      <div class="expenses-title-bar">
        <article class="item description">Description & Notes</article>
        <article class="item total">Total</article>
      </div>
      <div class="expenses-content" v-if="hasData">
        <div class="descriptionNotesCol" v-if="Object.keys(labor).length">
          <div class="header">
            <p>Labor</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in labor" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div class="descriptionNotesCol" v-if="Object.keys(av).length">
          <div class="header">
            <p>A/V</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in av" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div class="descriptionNotesCol" v-if="Object.keys(technology).length">
          <div class="header">
            <p>Technology</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in technology" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div class="descriptionNotesCol" v-if="Object.keys(branding).length">
          <div class="header">
            <p>Branding & Signage</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in branding" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div class="descriptionNotesCol" v-if="Object.keys(experiences).length">
          <div class="header">
            <p>Experiences</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in experiences" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div
          class="descriptionNotesCol"
          v-if="Object.keys(entertainment).length"
        >
          <div class="header">
            <p>Entertainment</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in entertainment" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div class="descriptionNotesCol" v-if="Object.keys(rentals).length">
          <div class="header">
            <p>Rentals</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in rentals" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div
          class="descriptionNotesCol"
          v-if="Object.keys(customHeader).length"
        >
          <div class="header">
            <p>{{ additionalHeaderTitle }}</p>
            <div class="header-line"></div>
          </div>
          <div class="info" v-for="(item, index) in customHeader" :key="index">
            <p>{{ item.description }}</p>
            <p v-if="isNaN(item.price) || item.price == ''">{{ item.price }}</p>
            <p v-else>{{ item.price | currency }}</p>
          </div>
        </div>

        <div class="descriptionNotesCol" v-if="stateTaxFee">
          <div class="header">
            <p>State Tax Fee</p>
            <div class="header-line"></div>
          </div>
          <div class="info">
            <p></p>
            <p v-if="isNaN(stateTaxFee) || stateTaxFee == ''">
              {{ stateTaxFee }}
            </p>
            <p v-else>{{ stateTaxFee | currency }}</p>
          </div>
        </div>
        <div class="expenses-content-divider"></div>
        <div class="expenses-content-subtotal">
          <h3>Event Expenses Subtotal</h3>
          <h1>{{ subTotal | currency }}</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EventExpenses",
  data() {
    return {
      bg: null,
      labor: null,
      av: null,
      technology: null,
      branding: null,
      experiences: null,
      entertainment: null,
      rentals: null,
      customHeader: null,
      additionalHeaderTitle: null,
      stateTaxFee: null,
      hasData: null,
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "105") {
          this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
        }
        if (page.id === "111") {
          let data = JSON.parse(page.copy);
          this.labor = data.labor;
          this.av = data.av;
          this.technology = data.technology;
          this.branding = data.branding;
          this.experiences = data.experiences;
          this.entertainment = data.entertainment;
          this.rentals = data.rentals;
          this.customHeader = data.customHeader;
          this.additionalHeaderTitle = data.additionalHeaderTitle;
          this.stateTaxFee = data.stateTaxFee;
          this.hasData = true;
        }
      });
    }

    if (this.$route.name === "docraptor") {
      setTimeout(() => {
        let pages = this.$store.state.epitch.ePitch.pages;

        pages.forEach((page) => {
          if (page.id === "105") {
            this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
          }
          if (page.id === "111") {
            let data = JSON.parse(page.copy);
            this.labor = data.labor;
            this.av = data.av;
            this.technology = data.technology;
            this.branding = data.branding;
            this.experiences = data.experiences;
            this.entertainment = data.entertainment;
            this.rentals = data.rentals;
            this.customHeader = data.customHeader;
            this.additionalHeaderTitle = data.additionalHeaderTitle;
            this.stateTaxFee = data.stateTaxFee;
            this.hasData = true;
          }
        });
      }, 3000);
    }
  },
  computed: {
    subTotal() {
      let totalPrice = 0;
      this.labor.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.av.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.technology.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.branding.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.experiences.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.entertainment.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.rentals.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.customHeader.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });

      if (!isNaN(this.stateTaxFee) && this.stateTaxFee !== "") {
        totalPrice += parseFloat(this.stateTaxFee);
      }
      return totalPrice;
    },
  },
};
</script>

<style lang="scss" scoped>
#EventExpenses {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-image: url("~@/assets/images/Custom/why-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
  }
  .expenses {
    padding: 183px 0 0 333px;
    width: calc(93% - 268px);
    position: relative;
    z-index: 1;
    padding-bottom: 100px;
    &-title-bar {
      position: relative;
      height: 34px;
      width: 100%;
      background-image: linear-gradient(0deg, #d5d5d5 0%, #ffffff 100%);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px 0 13px;

      .item {
        position: absolute;
        font-family: "ArtegraSans-Bold";
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.75px;
        line-height: 30px;
        text-transform: uppercase;
      }
      .description {
        left: 13px;
      }
      .total {
        right: 10px;
      }
    }
    &-content {
      position: relative;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      padding: 13px 0 14px 0;
      .descriptionNotesCol {
        &:first-child {
          .header {
            padding-top: 0;
          }
        }
      }
      .header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 10px 7px 13px;
        p {
          font-family: "ArtegraSans-Bold";
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 21px;
          margin-right: 20px;
        }
        &-line {
          height: 1px;
          background: #ffffff;
          flex-grow: 1;
        }
      }
      .first {
        padding-top: 0;
      }
      .info {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: "ArtegraSans-Light";
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 20px;
        padding: 0 10px 0 13px;
      }
      .tax {
        justify-content: flex-end;
      }
      &-divider {
        height: 2px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
        margin: 17px 0;
      }
      &-subtotal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px 0 13px;
        h3 {
          font-family: "ArtegraSans-Bold";
          font-size: 15px;
          color: #ffffff;
          letter-spacing: 0.94px;
          line-height: 30px;
          text-transform: uppercase;
        }
        h1 {
          font-family: "ArtegraSans-Light";
          font-size: 28px;
          color: #ffffff;
          letter-spacing: 1.75px;
          text-align: right;
          line-height: 30px;
        }
      }
    }
  }
}
@media print, screen {
  #EventExpenses.docraptor {
    //min-height: 100vh;
    background-image: url("~@/assets/images/Custom/why-pdf.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 900px;
    width: 100%;
    &:before {
      min-height: 100vh;
      height: 100vh;
      display: none;
    }

    .expenses {
      padding: 70px 100px;
      width: 100%;
      padding-bottom: 0px;

      .expenses-title-bar {
        display: none;
      }
      .expenses-content {
        display: flex;
        flex-wrap: wrap;
        .descriptionNotesCol {
          width: 50%;
        }
        .expenses-content-divider,
        .expenses-content-subtotal {
          width: 100%;
        }
      }
    }
  }
}
</style>