import Vuex from 'vuex'
import Vue from 'vue'
import global from './modules/global'
import epitch from './modules/epitch'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        global,
        epitch
    }
})