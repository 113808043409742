<template>
  <section id="EventExpensesMobile" :style="{ backgroundImage: `url(${bg})` }">
    <div class="background-overlay"></div>
    <h2>
      Event Expenses
      <div class="line"></div>
    </h2>
    <div class="accordion" v-if="hasData">
      <div
        class="accordion-item"
        :class="{ 'is-closed': !labor.isActive }"
        v-if="Object.keys(labor).length"
      >
        <div
          class="accordion-item-content"
          @click="labor.isActive = !labor.isActive"
        >
          <span class="title">Labor</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: labor.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in labor"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !av.isActive }"
        v-if="Object.keys(av).length"
      >
        <div class="accordion-item-content" @click="av.isActive = !av.isActive">
          <span class="title">A/V</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: av.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in av"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !technology.isActive }"
        v-if="Object.keys(technology).length"
      >
        <div
          class="accordion-item-content"
          @click="technology.isActive = !technology.isActive"
        >
          <span class="title">Technology</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: technology.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in technology"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !branding.isActive }"
        v-if="Object.keys(branding).length"
      >
        <div
          class="accordion-item-content"
          @click="branding.isActive = !branding.isActive"
        >
          <span class="title">Branding & Signage</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: branding.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in branding"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !experiences.isActive }"
        v-if="Object.keys(experiences).length"
      >
        <div
          class="accordion-item-content"
          @click="experiences.isActive = !experiences.isActive"
        >
          <span class="title">Experiences</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: experiences.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in experiences"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !entertainment.isActive }"
        v-if="Object.keys(entertainment).length"
      >
        <div
          class="accordion-item-content"
          @click="entertainment.isActive = !entertainment.isActive"
        >
          <span class="title">Entertainment</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: entertainment.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in entertainment"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !rentals.isActive }"
        v-if="Object.keys(rentals).length"
      >
        <div
          class="accordion-item-content"
          @click="rentals.isActive = !rentals.isActive"
        >
          <span class="title">Rentals</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: rentals.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in rentals"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !customHeader.isActive }"
        v-if="Object.keys(customHeader).length"
      >
        <div
          class="accordion-item-content"
          @click="customHeader.isActive = !customHeader.isActive"
        >
          <span class="title">{{ additionalHeaderTitle }}</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: customHeader.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div
            class="accordion-item-description-body"
            v-for="(item, index) in customHeader"
            :key="index"
          >
            <div class="middle">
              <div class="middle-item">
                <p>{{ item.description }}</p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(item.price) || item.price == ''">
                    {{ item.price }}
                  </p>
                  <p v-else>{{ item.price | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="accordion-item"
        :class="{ 'is-closed': !stateTaxFeeActive.isActive }"
        v-if="stateTaxFee"
      >
        <div
          class="accordion-item-content"
          @click="stateTaxFeeActive.isActive = !stateTaxFeeActive.isActive"
        >
          <span class="title">State Tax Free</span>
          <img
            src="@/assets/icons/Custom/close.svg"
            :class="{ rotate: stateTaxFeeActive.isActive }"
          />
        </div>
        <div class="accordion-item-description">
          <div class="accordion-item-description-body">
            <div class="middle">
              <div class="middle-item">
                <p></p>
              </div>
              <div class="total">
                <div class="total-content">
                  <p v-if="isNaN(stateTaxFee) || stateTaxFee == ''">
                    {{ stateTaxFee }}
                  </p>
                  <p v-else>{{ stateTaxFee | currency }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="subtotal">
      <div class="top">
        <h3>Event Expenses Subtotal</h3>
        <h1>{{ subTotal() | currency }}</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EventExpensesMobile",
  data() {
    return {
      bg: null,
      labor: null,
      av: null,
      technology: null,
      branding: null,
      experiences: null,
      entertainment: null,
      rentals: null,
      customHeader: null,
      additionalHeaderTitle: null,
      stateTaxFeeActive: {},
      stateTaxFee: null,
      hasData: null,
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;

    pages.forEach((page) => {
      if (page.id === "105") {
        this.bg = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
      }
      if (page.id === "111") {
        let data = JSON.parse(page.copy);
        this.labor = data.labor;
        this.av = data.av;
        this.technology = data.technology;
        this.branding = data.branding;
        this.experiences = data.experiences;
        this.entertainment = data.entertainment;
        this.rentals = data.rentals;
        this.customHeader = data.customHeader;
        this.additionalHeaderTitle = data.additionalHeaderTitle;
        this.stateTaxFee = data.stateTaxFee;

        this.hasData = true;

        this.$set(this.labor, "isActive", false);
        this.$set(this.av, "isActive", false);
        this.$set(this.technology, "isActive", false);
        this.$set(this.branding, "isActive", false);
        this.$set(this.experiences, "isActive", false);
        this.$set(this.entertainment, "isActive", false);
        this.$set(this.rentals, "isActive", false);
        this.$set(this.customHeader, "isActive", false);
        this.$set(this.stateTaxFeeActive, "isActive", false);
      }
    });
  },
  methods: {
    subTotal() {
      let totalPrice = 0;
      this.labor.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.av.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.technology.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.branding.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.experiences.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.entertainment.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.rentals.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });
      this.customHeader.forEach((item) => {
        if (!isNaN(item.price) && item.price !== "") {
          totalPrice += parseFloat(item.price);
        }
      });

      if (!isNaN(this.stateTaxFee) && this.stateTaxFee !== "") {
        totalPrice += parseFloat(this.stateTaxFee);
      }
      return totalPrice;
    },
  },
};
</script>

<style lang="scss" scoped>
#EventExpensesMobile {
  position: relative;
  width: 100vw;
  background-image: url("~@/assets/images/Custom/why-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 44px 53px 44px;
  @media (max-width: $lg) {
    background-image: none;
  }
  @media (max-width: $md) {
    padding: 0 50px;
  }
  @media (max-width: $sm) {
    padding: 0 20px;
  }
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.84) 48%,
      rgba(0, 0, 0, 0) 89%
    );
  }

  h2 {
    position: relative;
    font-family: "ArtegraSans-Bold";
    font-size: 26px;
    color: #ffffff;
    letter-spacing: 1.62px;
    line-height: 25px;
    text-transform: uppercase;
    width: fit-content;
    z-index: 2;
    @media (min-width: $lg) {
      display: none;
    }
    .line {
      height: 2px;
      background: #ffffff;
      box-shadow: 0 0 9px 2px #ffffff;
      margin: 20px 0;
      @media (min-width: $lg) {
        display: none;
      }
    }
  }
  .divider {
    position: relative;
    height: 2px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
    margin: 17px 0;
  }
  .subtotal {
    position: relative;
    margin-bottom: 41px;
    .top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 10px 0 19px;
      h3 {
        font-family: "ArtegraSans-Bold";
        font-size: 15px;
        color: #ffffff;
        letter-spacing: 0.54px;
        line-height: 14px;
        margin-bottom: 5px;
      }
      h1 {
        font-family: "ArtegraSans-Light";
        font-size: 28px;
        color: #ffffff;
        letter-spacing: 1.75px;
        line-height: 30px;
        margin-bottom: 33px;
      }
    }
    p {
      font-family: "ArtegraSans-Light";
      font-size: 13px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
  .accordion-item-description {
    padding-top: 0;
  }
  .accordion-item-description-body .middle {
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>